body,
html {
  height: 100%;
}

body {
  font-family: Arial;
  font-weight: 500;
  font-feature-settings: 'zero' 0;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  margin: 0;
  padding: 0;
  background-color: var(--color-layer-base);
  color: var(--color-text-base);
  overflow: hidden;
}

body::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

#root {
  height: 100%;
}

div {
  box-sizing: border-box;
}

div::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

a,
a:active,
a:hover,
a:visited {
  color: inherit;
  text-decoration: none;
}

[role='button'],
a,
button {
  -webkit-user-select: none;
  user-select: none;
}

.price-increase {
  color: #3fb68b !important;
}

.price-decrease {
  color: #ff5353 !important;
}

:focus {
  outline: none;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: initial;
}

::-webkit-scrollbar {
  display: none;
}

@keyframes dropdown {
  0% {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

@keyframes cancelOrder {
  0% {
    opacity: 0;
    display: none;
  }

  to {
    opacity: 1;
    display: flex;
  }
}
